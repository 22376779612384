'use client';

import useBi from '@haaretz/s-use-bi';
import React from 'react';

export default function ErrorPageView() {
  const biRequest = useBi('pageview');

  React.useEffect(() => {
    if (biRequest) {
      const timeout = setTimeout(
        () =>
          biRequest({
            page_type: 'Error',
          }),
        500
      );

      return () => timeout && clearTimeout(timeout);
    }

    return undefined;
  }, [biRequest]);

  return null;
}
